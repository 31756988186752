import React from "react";
import Section from "./Section.js";
import I18n from "./I18n";
import TestbedTallyCounter from "./TestbedTallyCounter.js";

const TestbedAbout = props => {
    return (
        <Section
            scrollId="about"
            sectionProps={{
                className: "slide whiteSlide",
                "data-name": "about"
            }}
        >
            <div className="content">
                <div className="container">
                    <div className="wrap">
                        <div className="fix-10-12 toCenter">
                            <h1 className="ae-1 fromLeft header-11 light">
                                <I18n label="TB_About" />
                            </h1>
                        </div>
                        <div className="fix-11-12">
                            <div className="ae-2 light">
                                <I18n label="TB_AboutText" markdown />
                            </div>
                            <div
                                style={{ marginTop: "64px", color: "#f08073" }}
                            >
                                <TestbedTallyCounter />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default TestbedAbout;
